.our-client {
    /* border: 5px solid green; */
    padding: 50px 100px;
    text-align: center;
}

.our-client h1 {
    margin-bottom: 50px;
}

.our-client-slider {
    /* border: 5px solid red; */
    display: flex;
    gap: 20px;
}

.our-client-item {
    /* border: 5px solid blue; */
    height: 30vh;
    padding: 20px;
}

.our-client-img {
    /* border: 5px solid yellow; */
    width: 100%;
    height: 100%;
    object-fit: contain;
}



/* TABLET VIEW */




/* MOBILE VIEW */
@media screen and (max-width: 600px) {
    .our-client {
        /* border: 5px solid green; */
        padding: 50px 20px;
    }
}