.offer {
    /* border: 5px solid red; */
    padding: 50px 100px;
    text-align: center;
    background-color: #eee;
}

.offer h1 {
    margin-bottom: 50px;
}

.offer-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
}

.offer-item {
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* align-items: center; */
    text-align: center;
    padding: 20px;
    width: 25vw;
    box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.1); 
    border-radius: 10px;
    background-color: #fff;
}

.offer-item h3 {
    color: #00008B;
}

.offer-item p {
    /* font-size: 14px; */
    color: #555;
    line-height: 1.5;
}

.offer-span {
    /* border: 5px solid red; */
    display: block;
    width: 100%;
    height: 150px;
}

.offer-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


/* TABLET VIEW */
@media screen and (max-width: 900px) {
    .offer {
        padding: 50px 20px;
    }

    .offer-group {
        /* flex-direction: column; */
        flex-wrap: wrap;
    }

    .offer-item {
        width: 40vw;
    }
}



/* MOBILE VIEW */
@media screen and (max-width: 600px) {
    .offer {
        padding: 50px 20px;
    }

    .offer-group {
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .offer-item {
        width: 100%;
    }
}