.contact-address {
    box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.1); 
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 20px;
    border-radius: 10px;
    background-color: #eee;
    flex: 1;
    color: rgba(0, 0, 0, 0.5);
}

.contact-address-line {
    display: flex;
    align-items: center;
    gap: 10px;
    color: rgba(0, 0, 0, 0.8);
}

.contact-address-line svg {
    color: #00008B;
    font-size: 20px;
}