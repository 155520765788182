/* 
  font-family: 'Ubuntu', sans-serif;
  font-family: 'Roboto', sans-serif;
  font-family: 'Poppins', sans-serif;
  font-family: 'Inter', sans-serif;
  font-family: 'Open Sans', sans-serif;

  color: #00008B; deep blue
  color: #ffd700; orange/yellow
  background-color: #29166f; for profile section
  color: #87CEEB; sky blue
  color: rgb(255, 215, 0); orange/yellow
*/

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'ubuntu', sans-serif;
}
