.contact-form {
  /* border: 5px solid red; */
  box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #eee;
  flex: 1;
  padding: 20px;
  color: rgba(0, 0, 0, 0.5);
}

.input-item {
  border: 2px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
}

input, textarea {
  width: 100%;
  padding: 10px;
  border: none;
}

input:focus, textarea:focus {
  outline: none;
}

.submit {
  /* border: 5px solid red; */
  /* border: none; */
  background-color: #29166f;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  /* width: 100px; */
}

.contact-form h1, .contact-form p {
  margin-bottom: 20px;
}

.mail-response {
  /* border: 2px solid red; */
  margin-top: 20px;
  /* padding: 10px; */
}

.success-msg {
  border: 2px solid green;
  color: green;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.fail-msg {
  border: 2px solid red;
  color: red;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.x {
  font-size: 20px;
  cursor: pointer;
}