.testimonial {
    /* border: 5px solid red; */
    padding: 50px 100px;
    text-align: center;
}

.quote {
    color: #ffd700;
    font-size: 20px;
}

.testimonial h1 {
    margin-bottom: 50px;
}

.testimonial-card-container {
    /* border: 5px solid blue; */
    display: flex;
    gap: 50px;
    justify-content: center;
    flex-wrap: wrap;
}

.testimonial-card {
    /* border: 5px solid yellow; */
    flex: 1;
    box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: rgba(0, 0, 0, 0.7);
}

.testimonial-review {
    /* border: 5px solid green; */
    font-style: oblique;
}

/* .testimonial-pic-name {
    border: 5px solid indigo;
} */

.testimonial-pic {
    border: 5px solid #ffd700;
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

/* .testimonial-name {
    border: 5px solid royalblue;
} */



/* TABLET VIEW */
@media screen and (max-width: 900px) {
    .testimonial {
        padding: 50px 20px;
    }

    .testimonial-card {
        width: 40vw;
        /* border: 5px solid red; */
    }

    .testimonial-card-container {
        /* border: 5px solid green; */
        flex-wrap: wrap;
    }
}



/* MOBILE VIEW */
@media screen and (max-width: 600px) {
    .testimonial {
        padding: 50px 20px;
    }

    .testimonial-card-container {
        flex-direction: column;
    }

    .testimonial-card {
        width: 100%;
    }
}