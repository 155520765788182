.hero {
    min-height: 85vh;
    background: black;
    position: relative;
}

.video-div {
    /* border: 5px solid green; */
    height: 85vh;
    width: 100%;
    opacity: 1;
}

.vid {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-text {
    /* border: 5px solid red; */
    color: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: fit-content;
    height: fit-content;
    text-align: center;
}

.hero-text h1 {
    font-size: 50px;
}

.hero-link {
    /* border: 5px solid green; */
    display: inline-block;
    margin-top: 50px;
    color: #fff;
    background-color: #00008B;
    text-decoration: none;
    padding: 20px 40px;
    border-radius: 10px;
    font-weight: 900;
}

.hero-link:hover {
    background-color: #87CEEB;
    color: #00008B;
}