.footer {
    background: #29166f url('../images/footer-bg.svg') no-repeat;
    color: rgba(255, 255, 255, 0.7);
    padding: 50px 100px;
    min-height: 15vh;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.footer h2 {
    color: #fff;
    margin-bottom: 20px;
}

.footer svg {
    font-size: 24px;
}

/* .footer-address {
    border: 5px solid red;
} */

.footer-address-content {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* .footer-contact {
    border: 5px solid yellow;
} */

.footer-contact-content {
    display: flex;
    align-items: center;
    gap: 10px;
    /* margin-top: 20px; */
}

/* .footer-social {
    border: 5px solid blue;
} */

.footer-social-icons {
    display: flex;
    gap: 20px;
}

.footer-social-link {
    color: rgba(255, 255, 255, 0.5);
}

.footer-social-link:hover {
    color: #fff;
}

.footer-copyright {
    /* border: 5px solid green; */
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.footer-copyright-link {
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
}

.footer-copyright-link:hover {
    text-decoration: underline;
}


/* CSS SETTING FOR TABLET VIEW */
@media screen and (max-width: 900px) {
    .footer {
        padding: 50px 20px;
        gap: 20px;
        flex-wrap: wrap;
    }
}



/* CSS SETTING FOR MOBILE VIEW */
@media screen and (max-width: 600px) {
    .footer {
        padding: 50px 20px;
        flex-direction: column;
        align-items: center;
        gap: 50px;
    }

    .footer-address, .footer-contact, .footer-social, .footer-copyright {
        text-align: center;
    }
}