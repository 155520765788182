.whatsapp {
    z-index: 5000;
    position: fixed;
    right: 100px;
    bottom: 20px;
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .whatsapp-inner {
    position: relative;
    height: 100%;
    width: 100%;
  }
  
  .whatsapp-text {
    background-color: #eee;
    flex: 1;
    width: 100%;
    height: 80%;
    /* padding: 20px; */
    border-radius: 20px;
  }
  
  .whatsapp-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: green;
    color: #fff;
    padding: 20px;
    border-radius: 20px 20px 0 0;
    margin-bottom: 50px;
  }
  
  .whatsapp-question {
    padding: 0 20px;
    margin-bottom: 30px;
  }
  
  .whatsapp-times {
    font-size: 24px;
    cursor: pointer;
  }
  
  .whatsapp-btn-div {
    padding: 0 20px 20px;
  }
  
  .whatsapp-btn {
    padding: 15px 30px;
    background-color: green;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none;
  }
  
  .whatsapp-image {
    width: 100px;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 0;
  }



  /* TABLET VIEW */
  @media screen and (max-width: 900px) {
    .whatsapp {
      right: 20px;
    }
  }


  /* MOBILE VIEW */
  @media screen and (max-width: 600px) {
    .whatsapp {
      right: 20px;
    }
  }