.service {
    padding: 50px 100px;
    min-height: 85vh;
    /* background-image: url('../images/bg6.jpg'); */
    background-color: #eee;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.service-group p {
    line-height: 1.8;
    color: rgba(0, 0, 0, 0.7);
}

.service h1, .service-group h2 {
    color: #00008B;
}

.service-group hr {
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.2);
}

/* .service-group {
    border: 5px solid red;
} */

.service-inner-group {
    display: flex;
    gap: 50px;
}

.service-img-div {
    flex: 1;
    /* background-color: #00008B; */
    background-image: linear-gradient(360deg, #87CEEB 0%, #29166f 100%);
    padding: 10px;
    /* border-radius: 10px; */
}

.service-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* border-radius: 10px; */
}

.service-text {
    flex: 1;
    line-height: 1.8;
    color: rgba(0, 0, 0, 0.7);
    /* background-color: #fff; */
    /* background-image: linear-gradient(360deg, #87CEEB 0%, #29166f 100%); */
    /* padding: 10px; */
    /* border-radius: 10px; */
}



/* Tablet view */
@media screen and (max-width: 900px) {
    .service {
        padding: 50px 20px;
        gap: 30px;
    }

    .service-inner-group {
        flex-direction: column;
        gap: 20px;
    }

    .img-1 {
        order: 1;
    }

    .text-1 {
        order: 2;
    }

    .img-2 {
        order: 3;
    }

    .text-2 {
        order: 4;
    }

    .img-3 {
        order: 5;
    }

    .text-3 {
        order: 6;
    }

    .img-4 {
        order: 7;
    }

    .text-4 {
        order: 8;
    }
}



/* Mobile view */
@media screen and (max-width: 600px) {
    .service {
        padding: 50px 20px;
        gap: 30px;
    }

    .service-inner-group {
        flex-direction: column;
        gap: 20px;
    }

    .img-1 {
        order: 1;
    }

    .text-1 {
        order: 2;
    }

    .img-2 {
        order: 3;
    }

    .text-2 {
        order: 4;
    }

    .img-3 {
        order: 5;
    }

    .text-3 {
        order: 6;
    }

    .img-4 {
        order: 7;
    }

    .text-4 {
        order: 8;
    }
}