.contact {
    min-height: 85vh;
    background-image: url('../images/bg10.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.contact-img-div {
    height: 30vh;
}

.contact-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.contact-details {
    /* border: 5px solid red; */
    padding: 50px 100px;
    display: flex;
    gap: 50px;
}


@media screen and (max-width: 900px) {
    .contact-details {
        padding: 50px 20px;
    }
}


@media screen and (max-width: 600px) {
    .contact-details {
        padding: 50px 20px;
        flex-direction: column;
    }
}