/* .header-container {
    position: sticky;
    top: 0;
    z-index: 900;
} */

.header {
    /* border: 5px solid red; */
    height: 15vh;
    background-image: linear-gradient(90deg, #87CEEB 0%, #29166f 100%);
    padding: 0 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.logo {
    width: 150px;
}

.bars {
    color: #fff;
    font-size: 30px;
    display: none;
    cursor: pointer;
}

.nav {
    list-style: none;
    display: flex;
    gap: 30px;
}

/* .nav-item {
    border: 2px solid red;
} */

.nav-link {
    /* color: #87CEEB; */
    color: #fff;
    font-weight: 900;
    text-decoration: none;
}

.nav-link:hover {
    color: #87CEEB;
}

@media screen and (max-width: 900px) {
    .header {
        padding: 0 20px;
    }
}

@media screen and (max-width: 600px) {
    .header {
        padding: 0 20px;
    }

    .logo {
        width: 120px;
    }

    .bars {
        display: block;
    }

    .nav {
        /* display: none; */
        /* background: #29166f; */
        background-image: linear-gradient(90deg, #87CEEB 0%, #29166f 100%);
        flex-direction: column;
        position: absolute;
        right: 100vw;
        top: 15vh;
        width: 100vw;
        height: 100vh;
        gap: 30px;
        align-items: flex-end;
        padding: 20px;
        transition: right 1s;
        z-index: 1000;
    }

    .show {
        right: 0;
    }
}