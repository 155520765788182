.about {
    /* border: 5px solid red; */
    background-image: url('../images/plan.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    min-height: 85vh;
    padding: 50px 0;
}

.about-h1 {
    padding: 0 100px 50px;
}

.company-profile {
    /* border: 5px solid green; */
    background-color: #29166f;
    padding: 50px 100px;
    color: #fff;
    display: flex;
    gap: 50px;
}

.company-profile-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.company-profile-text {
    line-height: 1.5;
    font-family: 'Inter', sans-serif;
    color: rgba(255, 255, 255, 0.8);
}

.company-profile-text h1, .company-profile-text p {
    margin-bottom: 30px;
}

.about-values {
    padding: 50px 100px;
    display: flex;
    justify-content: center;
    gap: 50px;
    flex-wrap: wrap;
}

.about-item {
    width: 40vw;
    box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.1); 
    padding: 20px;
    background-color: #fff;
    /* background-color: #87CEEB; */
    border-radius: 10px;
}

.about-item h2 {
    /* color: #ffd700; */
    color: #00008B;
    /* color: #87CEEB; */
    margin-bottom: 15px;
}

.about-item p {
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.7);
}

.values-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: rgba(0, 0, 0, 0.7);
}

.list-icon {
    color: #ffd700;
}



@media screen and (max-width: 900px) {
    .about-h1 {
        padding: 0 20px 50px;
    }

    .company-profile {
        /* flex-direction: column; */
        padding: 50px 20px;
    }

    .about-values {
        padding: 50px 20px;
    }

    /* .about-item {
        width: 100%;
    } */
}



@media screen and (max-width: 600px) {
    .about-h1 {
        padding: 0 20px 50px;
    }

    .company-profile {
        flex-direction: column;
        padding: 50px 20px;
    }

    .about-values {
        padding: 50px 20px;
    }

    .about-item {
        width: 100%;
    }
}